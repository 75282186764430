<template>
  <div>
    <div class="d-flex justify-content-center mt-10">
      <div class="container">
        <!--begin::Row-->
        <div class="row d-flex justify-content-center">
          <div :class="cols">
            <KTHeader
            :menu="false"
            ></KTHeader>
            <div class="mt-10 mb-10">
              <div class="text-center">
                <qrcode-vue :value="reservation.code" :size="qrSize()" level="H"/>
                <span>{{ reservation.code }}</span><br>
                <span>{{ convertDateTime(reservation.createdAt) }}</span>
              </div>
            </div>
            <div class="text-center">
              <div class="container">
                <!--begin::Row-->
                <div class="row">
                  <div class="col-md-12">
                    <BlockCitizen
                        :citizen="citizen"
                        v-if="citizen.name !== undefined"
                        :pdf="pdf"
                    ></BlockCitizen>
                  </div>
                  <div class="col-md-12">
                    <BlockReservation
                        :reservation="reservation"
                    ></BlockReservation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KTHeader from "@/view/layout/header/Header";
import BlockCitizen from "@/view/pages/reservations/blocks/BlockCitizen";
import BlockReservation from "@/view/pages/reservations/blocks/BlockReservation";
import QrcodeVue from 'qrcode.vue';

export default {
  name: "SheetResume",
  props: {
    cols: String,
    pdf: Boolean,
    citizen: {},
    reservation: {}
  },
  components: {
    KTHeader,
    BlockCitizen,
    BlockReservation,
    QrcodeVue
  },
  methods: {
    qrSize() {
      if (window.innerWidth < 1300) {
        return 150
      }
      if (window.innerWidth < 1400) {
        return 180
      }
      if (window.innerWidth < 1500) {
        return 180
      }
      return 180;
    },
    convertDateTime(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    }
  }
}
</script>
