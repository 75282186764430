<template>
  <div>
    <v-card tile></v-card>
    <div class="card card-custom card-transparent">
      <div class="card card-custom card-shadowless rounded-top-0">
        <div class="card-body p-0">
          <SheetResume
              v-if="isAuthenticated"
              :reservation="reservation"
              cols="col-6"
              :citizen="currentUserBaseInfo"
              :event="event_found"
              :dose="dose_found"
              :pdf="false"
          ></SheetResume>
          <div class="text-center py-4" v-if="isAuthenticated">
            <span class="btn btn-success" @click="generatePdf()">Salva in pdf</span>
          </div>
          <div v-if="!isAuthenticated" class="text-center text-warning py-10">
            <h2>
              {{ $t("PAGES.RESERVATIONS.ERROR.CITIZEN_NOT_LOGGED") }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
        v-if="isAuthenticated"
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1200"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-content-width="100%"
        v-show="false"
        :filename="reservation.code"
        ref="receiptPdf"
    >
      <section slot="pdf-content">
        <SheetResume
            cols="col-12"
            :reservation="reservation"
            :citizen="currentUserBaseInfo"
            :pdf="true"
        ></SheetResume>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SheetResume from "@/view/pages/reservations/blocks/SheetResume";
import {FETCH_RESERVATIONS_LIST} from "@/core/services/store/reservations.module";
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: "resumeReservation",
  data() {
    return {
      event: {},
      dose: {},
      event_date_time: {},
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1
      },
      reservation_filter: {
        query: [],
        filters: {
          _event: {
            code: {
              _eq: ''
            }
          },
          _citizen: {
            'citizen.code': {
              _eq: ''
            }
          },
          _cmb_and: "_event,_citizen",
        },
        sort: {},
        page: 1
      },
      reservation: {},
      event_found: {},
      dose_found: {}
    }
  },
  mounted() {
    // this.reservation_filter.filters._event.code._eq = this.$route.params.code
    // this.reservation_filter.filters._citizen['citizen.code']._eq = this.currentUserBaseInfo.code
    // this.$store.dispatch(FETCH_RESERVATIONS_LIST, this.reservation_filter);
    if (this.$route.params.r !== undefined && this.$route.params.r === "1") {
      setTimeout(() => {
        this.$router.push({name: "resume_reservation", params: {code: this.$route.params.code}});
      }, 2000);
    }
  },
  computed: {
    ...mapGetters([
      "currentUserBaseInfo",
      "isAuthenticated",
      "reservationsList",
      "getRequestVerifiedStatus"
    ])
  },
  methods: {
    generatePdf() {
      this.$refs.receiptPdf.generatePdf();
    },
    async beforeDownload({html2pdf, options, pdfContent}) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      }).save()
    }
  },
  components: {
    SheetResume,
    VueHtml2pdf
  },
  watch: {
    getRequestVerifiedStatus() {
      this.reservation_filter.filters._event.code._eq = this.$route.params.code
      this.reservation_filter.filters._citizen['citizen.code']._eq = this.currentUserBaseInfo.code
      this.$store.dispatch(FETCH_RESERVATIONS_LIST, this.reservation_filter);
    },
    currentUserBaseInfo() {
      this.reservation_filter.filters._event.code._eq = this.$route.params.code
      this.reservation_filter.filters._citizen['citizen.code']._eq = this.currentUserBaseInfo.code
      if (this.currentUserBaseInfo.code !== undefined) {
        this.$store.dispatch(FETCH_RESERVATIONS_LIST, this.reservation_filter);
      }
    },
    reservationsList() {
      this.reservation = this.reservationsList.data !== undefined ? this.reservationsList.data[0] : {};
      this.event_found = this.reservation.event !== undefined ? this.reservation.event : {};
      this.dose_found = (this.reservation.event !== undefined && this.reservation.event.dose !== undefined) ? this.reservation.event : {};
    }
  }
}
</script>
