<template>
  <!-- begin:: Header -->
  <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
  >
    <div
        class="d-flex align-items-center justify-content-between"
        v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
        v-if="!isMobile"
    >
      <div class="!isMobile">
        <div class="d-flex align-items-stretch mr-3">
          <div class="header-logo">
            <router-link to="/">
              <img
                  alt="Logo"
                  :src="customConfig.header.logo"
                  class="logo-default max-h-100px"
              />
            </router-link>

            <span class="headerTitle pl-10">{{ customConfig.header.title }}</span>
          </div>
        </div>
      </div>
      <div v-if="menu">
        <KTTopbar v-if="isAuthenticated"></KTTopbar>
        <div v-else class="text-white"><a @click='$router.push({ name: "login_page" });' class="text-white">
         <span class="symbol symbol-30 symbol-lg-40">
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/General/User.svg"/>
          <!--end::Svg Icon-->
        </span>
           <!--<span class="symbol-label font-size-h5 font-weight-bold">S</span>-->
      </span>
          {{ $t("AUTH.LOGIN.BUTTON") }}
        </a></div>
      </div>
    </div>
    <div
        v-if="isMobile"
        id="kt_header_mobile"
        class="header-mobile align-items-center"
        v-bind:class="headerClasses"
    >
      <!--begin::Logo-->
      <a href="/">
        <img alt="Logo" :src="customConfig.header.logo" class="logo-default max-h-30px" />
        <span class="text--black pl-10">{{ customConfig.header.title }}</span>
      </a>

      <!--end::Logo-->
      <!--begin::Toolbar-->
      <div v-if="menu">
        <KTTopbar v-if="isAuthenticated && isMobile"></KTTopbar>
        <div v-if="!isAuthenticated && isMobile" class="text-white">
          <a @click='$router.push({ name: "login_page" });' class="text-black">
         <span class="symbol symbol-30 symbol-lg-40">
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/General/User.svg"/>
          <!--end::Svg Icon-->
        </span>
           <!--<span class="symbol-label font-size-h5 font-weight-bold">S</span>-->
      </span>
          {{ $t("AUTH.LOGIN.BUTTON") }}
        </a></div>
      </div>
  </div>
  <!-- end:: Header -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";

export default {
  name: "KTHeader",
  components: {
    KTTopbar
  },
  props: {
    menu: Boolean
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "isAuthenticated", "customConfig"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    },
    isMobile() {
      return screen.width <= 991 ? true : false
    }
  },
  created() {
    this.isMobile
  }
};
</script>

<style>
.header {
  background-color: #0C97B8 !important;
}

.headerTitle {
  text-decoration: none;
  color: #ffffff;
  font-size: 24px !important;
  transition: all 500ms;
  margin-top: 30px !important;
}
</style>
