<template>
  <div>
    <a
        href="#"
        class="btn btn-icon btn-clean btn-lg w-40px h-40px"
        id="kt_quick_user_toggle"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title=""
        data-original-title="User Profile"
    >
      <span class="symbol symbol-30 symbol-lg-40">
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/General/User.svg"/>
          <!--end::Svg Icon-->
        </span>
        <!--<span class="symbol-label font-size-h5 font-weight-bold">S</span>-->
      </span>
    </a>

    <div
        id="kt_quick_user"
        ref="kt_quick_user"
        class="offcanvas offcanvas-left p-10"
    >
      <!--begin::Header-->
      <div
          class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          {{ $t("COMMON.USER_PROFILE") }}
        </h3>
        <a
            href="#"
            class="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
          class="offcanvas-content pr-5 mr-n5 scroll"
          style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt=""/>
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
                href="#"
                class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ currentUserBaseInfo.name }} {{ currentUserBaseInfo.surname }}
            </a>
            <div class="text-muted mt-1"> {{ currentUserMemberGroup.group_name }}</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                          src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUserBaseInfo.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              {{ $t("COMMON.LOG_OUT") }}
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5" v-show="true"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0" v-show="true">
          <!--begin::Item-->
          <router-link
              to="#"
              @click.native="closeOffcanvas"
              href="#"
              class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                        src="media/svg/icons/General/User.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{$t("ASIDE.TABS.ROOT_EDIT_PROFILE")}}</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
              to="/events"
              @click.native="closeOffcanvas"
              href="#"
              class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                        src="media/svg/icons/General/Notification2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{$t("ASIDE.TABS.ROOT_NEW_RESERVATIONS")}}</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
              to="/list"
              @click.native="closeOffcanvas"
              href="#"
              class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Files/Selected-file.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{$t("ASIDE.TABS.ROOT_RESERVATIONS")}}</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
        </div>
        <!--end::Nav-->
        <!--begin::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import {LOGOUT} from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import {mapGetters} from "vuex";

export default {
  name: "KTQuickUser",
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

  },
  methods: {
    onLogout() {
      this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({name: "login_page"}));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  computed: {
    ...mapGetters(["currentUserBaseInfo", "currentUserMemberGroup"]),
    picture() {
      return process.env.BASE_URL + "media/custom_avatar.png";
    }
  }
};
</script>
